import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {BigNumber, ethers} from "ethers";
import ContractABI from "../../abi/contract.json";
import ERC20ABI from "../../abi/ERC20.json";
import {useEthers} from "vue-dapp";

@Module
export default
class Contract extends VuexModule {
    addresses = {
        "137": "0x5A8DB404f4437c0480E33b1B203679F54bA33FE4",// Mumbai
        "80001": "0xa4335544c708f3113E3a845C35D0740100986aCe"
    };
    contract: ethers.Contract | undefined; // eslint-disable-next-line
    plans = [] as any; // eslint-disable-next-line
    erc20 = [] as any; // eslint-disable-next-line
    meta = [] as any; // eslint-disable-next-line
    deposits = [] as any; // eslint-disable-next-line
    earnings = [] as any; // eslint-disable-next-line
    allowance = [] as any; // eslint-disable-next-line
    bonus = [] as any;

    get getPlans() {
        return this.plans
    }

    get getPlan() {
        return id => this.plans[id];
    }

    @Action({ rawError: true })
    async initContract() {
        await this.context.dispatch("loadContract");
        await this.context.dispatch("loadPlans");
        await this.context.dispatch("loadPlansTokens");
        this.context.dispatch("loadErc20Meta");
        this.context.dispatch("loadAllowance");
        this.context.dispatch("loadDeposits");
        this.context.dispatch("loadBonuses");
    }

    @Action({ rawError: true })
    async refresh() {
        const { provider } = useEthers()
        this.allowance = []
        this.deposits = [];
        this.bonus = [];
        this.context.dispatch("loadAllowance");
        this.context.dispatch("loadDeposits");
        this.context.dispatch("loadBonuses");
    }

    @Action({ rawError: true })
    loadContract() {
        const { address, provider } = useEthers()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.context.commit("setContract", new ethers.Contract(this.addresses[provider.value?.network.chainId], ContractABI, provider.value?.getSigner(address.value)))
    }

    @Action({ rawError: true })
    loadPlansTokens() {
        const { address, provider } = useEthers()
        this.plans.forEach((plan, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.context.commit("setERC20", [index, new ethers.Contract(plan.token, ERC20ABI, provider.value?.getSigner(address.value))])
        })
    }

    @Action({ rawError: true })
    async loadErc20Meta() {
        for (const token of this.erc20) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const meta = {
                address: token.address,
                symbol: await token.symbol(),
                decimals: await token.decimals()
            }
            this.context.commit("setMeta", meta)
        }
    }

    @Action({ rawError: true })
    async loadBonuses() {
        this.context.commit("resetBonus")
        const { address } = useEthers()
        const addresses = this.erc20.map(o => o.address)
        const uniqueAddress = this.erc20.filter(({id}, index) => !addresses.includes(id, index + 1))
        for (const token of uniqueAddress) {
            this.context.commit("setBonus", {
                token: token.address,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                bonus: await this.contract.getUserBonuses(address.value, token.address),
            })
        }
    }

    @Action({ rawError: true })
    async loadAllowance() {
        this.context.commit("resetAllowance")
        const { address, provider } = useEthers()
        const addresses = this.erc20.map(o => o.address)
        const uniqueAddress = this.erc20.filter(({id}, index) => !addresses.includes(id, index + 1))
        for (const token of uniqueAddress) {
            this.context.commit("setAllowance", {
                address: token.address,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                allowance: await token.allowance(address.value, this.addresses[provider.value?.network.chainId])
            })
        }
        // this.erc20.forEach((token, index) => {
        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //     // @ts-ignore
        //     this.context.commit("setAllowance", [index, token.allowance(address.value, this.address)])
        // })
    }

    @Action({ rawError: true })
    async loadPlans() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.context.commit("setPlans", await this.contract.getPlans())
    }

    @Action({ rawError: true })
    async loadDeposits() {
        const { address } = useEthers()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let deposits = await this.contract.getUserDeposits(address.value) as Array;
        let earnings
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            earnings = await Promise.all(deposits.map((item, index) => this.contract.calculateProfit(address.value, index)))
        } catch (e) {
            earnings = Array(deposits.length).fill(BigNumber.from(0));
        }
        deposits = deposits.map((item, index) => {
            return {
                index: index,
                earnings: earnings[index] ?? 0,
                plan: item.plan,
                interest: item.interest,
                start: item.start,
                finish: item.finish,
                amount: item.amount
            };
        })

        this.context.commit("setDeposits", deposits)
    }

    @Action({ rawError: true })
    async loadEarnings() {
        const { address } = useEthers()

        this.deposits.forEach((item, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.context.commit("setEarnings", [index, this.contract.calculateProfit(address.value, index)])
        })
    }

    @Mutation
    setPlans(plans) {
        this.plans = plans
    }

    @Mutation
    setContract(contract: ethers.Contract) {
        this.contract = contract
    }

    @Mutation
    setDeposits(deposits) {
        this.deposits = deposits
    }

    @Mutation
    setERC20(payload) {
        this.erc20[payload[0]] = payload[1]
    }

    @Mutation
    async setAllowance(payload) {
        this.allowance.push(payload);
    }

    @Mutation
    async setMeta(meta) {
        this.meta.push(meta)
    }

    @Mutation
    async setBonus(bonus) {
        this.bonus.push(bonus)
    }

    @Mutation
    async resetBonus() {
        this.bonus = []
    }

    @Mutation
    async resetAllowance() {
        this.allowance = [];
    }

    @Mutation
    async setEarnings(payload) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.earnings[payload[0]] = await payload[1]
    }

    get getDeposits() {
        return id => this.deposits.filter(item => item.plan == id)
    }

    get getERC20() {
        return id => this.erc20[id]
    }

    get getMeta() {
        return id => this.meta.find(item => item.address == id)
    }

    get getAllowance() {
        return id => this.allowance.find(item => item.address == id)
    }

    get getContract() {
        return this.contract
    }
}
