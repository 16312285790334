import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import { VueDapp } from "vue-dapp";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import {ethers} from "ethers";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueDapp,{
    80001: {
        chainId: ethers.utils.hexValue(80001),
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
        chainName: 'Mumbai',
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        nativeCurrency: {
            name: 'Mumbai',
            decimals: 18,
            symbol: 'MATIC',
        },
    },
    137: {
        chainId: ethers.utils.hexValue(137),
        blockExplorerUrls: ['https://polygonscan.com/'],
        chainName: 'Polygon',
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        nativeCurrency: {
            name: 'Polygon',
            decimals: 18,
            symbol: 'MATIC',
        },
    }
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
